import tools from '../libs/tools';
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
    data(){
        return {
            filters : null,
            pageTitle: "",
            tabMenus: [],
            model: { is_null: true },
            dirty: false,
            linkInNewPage: false,
            colors: {
                bg: {
                    PRIMARY: "#009688",
                    SECONDARY: "#607D8B",
                    DANGER: "#D32F2F",
                    INFO: "#027abc",
                    HELP: "#9C27B0",
                    WARNING: "#FBC02D"
                }
            }
        };
    },
    computed: {
        language(){
            if (this.lang) return this.languages[this.lang].label;
            return "";
        },
        languageString(){
            if (this.lang) return "( traduzione in " + this.language + " ) ";
            return "";
        },
        activeIndex(){
            let idx = 0;
            this.tabMenus.forEach(menu => {
                if (menu.code === this.lang){
                    idx = menu.idx;
                }
            });
            return idx;
        },
        lastUpdateDate() {
            return this.unix2date(this.model['last_update']);
        }
    },
    props: {
        lang: {
            default: "",
            type: String
        }
    },
    methods: {
        languageLabel(l){
            if (this.languages[l]) return this.languages[l].label;
            return l;
        },
        unix2date(ts){
            return tools.time.lastUpdate(ts);
        },
        ts2date(ts){
            return tools.time.lastUpdateFromTs(ts);
        },
        localeTs2Date(ts){
            return tools.time.localeLastUpdateFromTs(ts);
        },
        getFrontendUrl(url){
            if (url.startsWith("/")){
                url = url.substring(1);
            }
            return this.appUrl() + '/' + url;
        },
        appUrl(){
            return process.env.VUE_APP_SITE_URL.replace(/\/+$/, '');
        },
        getFrontendBookUrl(lang, slug){
            let subPath;
            switch (lang){
                case "it":
                    subPath = "libro";
                    break;
                case "es":
                    subPath = "libro";
                    break;
                case "en":
                    subPath = "book";
                    break;
                default:
                    "libro";
            }
            return this.appUrl() + "/" + lang + "/" + subPath + "/" + slug;
        },
        getFrontendArticleUrl(lang, slug){
            let subPath;
            switch (lang){
                case "it":
                    subPath = "approfondimento";
                    break;
                case "es":
                    subPath = "extra";
                    break;
                case "en":
                    subPath = "extra";
                    break;
                default:
                    "approfondimento";
            }
            return this.appUrl() + "/" + lang + "/" + subPath + "/" + slug;
        },
        resetModel(){
            this.model = { is_null: true };
        },
        gotoNew(){
            return this.$router.currentRoute.value.fullPath + '/new';
        },
        gotoId(id) {
            const path = this.$router.currentRoute.value.fullPath + '/' + id;
            return path;
        },
        navigateToId(id){
            return this.$router.push({ path: this.$route['meta']['basePath'] + '/' + id });
        },
        navigateToBase(){
            return this.$router.push({ path: this.$route['meta']['basePath'] + ""});
        },
        setPageTitle(title){
            this.pageTitle = title;
        },
        downloadFile(fileName, data){
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        },
        sluggify(text){
            return tools.models.sluggify(text);
        },
        toJSON(){
            return JSON.stringify(this.model);
        },
        dump(o){
            return JSON.stringify(o);
        },
        initFilters() {
            this.initFiltersByAllFields({});
        },
        initFiltersByAllFields(filters){
            this.filters = filters;
            this.filters['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS };
        },
        addFiltersByStartWithArray(fieldNames = []){
            fieldNames.forEach(fieldName => {
                this.addFiltersByStartWith(fieldName);
            });
        },
        addFiltersByStartWith(fieldName){
            this.filters[fieldName] = {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
        },
        addFiltersEquals(fieldName){
            this.filters[fieldName] = {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]}
        },
        addFiltersByDate(fieldName){
            this.filters[fieldName] = {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]}
        },
        clearFilter() {
            this.initFilters();
        },
    }
}
