import utils from '../libs/tools';

export default {
    data() {
        return {
            YesNoSelects: [
                { value: false, label: 'No' },
                { value: true, label: 'Yes' },
            ],
            toastLife: 3000
        };
    },
    methods: {
        spinIcon(def, cond){
            return cond ? "pi pi-spin pi-spinner" : def;
        },
        Toast() {
            return {
                error: (message, detail, life) => {
                    this.$toast.add({severity: 'error', summary: message, detail: detail, life: life || this.toastLife});
                },
                success: (message, detail, life) => {
                    this.$toast.add({severity: 'success', summary: message, detail: detail, life: life || this.toastLife});
                }
            };
        },
        ShowSuccess(message, detail = ''){
            this.$toast.add({severity: 'success', summary: message, detail: detail, life: this.toastLife});
        },
        ShowInfo(message, detail = ''){
            this.$toast.add({severity: 'info', summary: message, detail: detail, life: this.toastLife});
        },
        ShowWarning(message, detail = '', lifeTime = 0){
            if (!lifeTime) lifeTime = this.toastLife;
            this.$toast.add({severity: 'warn', summary: message, detail: detail, life: lifeTime});
        },
        ShowError(message, detail = ''){
            this.$toast.add({severity: 'error', summary: message, detail: detail, life: this.toastLife});
        },
        ShowApiError(err){
            this.ShowStandardError(err.response)
        },
        ShowStandardError(err, dur = 0){
            let errorMessage = "Errore generico";
            if (err && err.data && err.data.error && err.data.error.message){
                errorMessage = err.data.error.message;
            } else if (err && err.data && err.data.message){
                errorMessage = err.data.message;
            } else {
                console.log("non-standard-error", err);
            }
            if (dur === 0) dur = this.toastLife;
            this.$toast.add({severity: 'error', summary: 'Errore', detail: errorMessage, life: dur});
        },
        copyText(txt) {
            if (!navigator.clipboard) {
                this.ShowError("Attenzione", "La funzione di copia automatica è disponibile solo in https (PROD)");
                return;
            }
            navigator.clipboard.writeText(txt).then(() => {
                this.ShowInfo("Testo copiato", txt);
            });
        },
        Formatters() {
            return {
                bool2YesNo: val => val ? "Yes" : "No",
                time: {
                    ...utils.time,
                    ts2Date: ts => utils.time.dateFormat(utils.time.unixToDate(ts)),
                },
                api: {
                    removeMod(data) {
                        const clone = JSON.parse(JSON.stringify(data));
                        clone['modified-by'] = '';
                        return clone;
                    }
                },
                strings: {
                    cut: (text, chars) => {
                        let addDots = "";
                        if (text.length > chars) addDots = "...";
                        return text.substring(0, chars) + addDots;
                    },
                    cutLines: (text, lines = 0) => {
                        if (lines === 0) {
                            lines = parseInt(process.env.VUE_APP_NOTE_LINES, 10);
                        }
                        let l = text.split("\n");
                        if (l.length <= lines) {
                            return text
                        }

                        return l.slice(0, lines).join("\n") + "...[CUT]";
                    }
                }
            }
        },

    }
};
